<template>
  <van-pull-refresh
    v-model="isLoading"
    pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..."
    success-text="Refresh Berhasil"
    @refresh="onRefresh"
  >
    <div class="authentication">
      <div class="authentication_bg">
        <img src="@/assets/img/renzheng_bg.png" alt="" />
        <BaseHeaderBar
          title="Verifikasi Data"
          :showCustomerService="false"
          :showBackButton="true"
        />
      </div>
      <div class="content">
        <div class="authList">
          <div class="auth1">
            <div class="authName">
              <span>Informasi data identitas</span>
            </div>
            <div
              :class="authInfo.iddrentityInfoStatus ? 'authSuccess' : 'unAuth'"
              @click="goIdentity"
            >
              {{
                authInfo.iddrentityInfoStatus
                  ? "Sudah Verifikasi"
                  : "Belum Verifikasi"
              }}
            </div>
          </div>
          <div class="auth2">
            <div class="authName">
              <span>Informasi Dasar</span>
            </div>
            <div
              :class="authInfo.indrformationStatus ? 'authSuccess' : 'unAuth'"
              @click="goBasicInfo"
            >
              {{
                authInfo.indrformationStatus
                  ? "Sudah Verifikasi"
                  : "Belum Verifikasi"
              }}
            </div>
          </div>
          <div class="auth3">
            <div class="authName">
              <span>Informasi Bank</span>
            </div>
            <div
              :class="authInfo.badrnkStatus ? 'authSuccess' : 'unAuth'"
              @click="goBankInfo"
            >
              {{
                authInfo.badrnkStatus ? "Sudah Verifikasi" : "Belum Verifikasi"
              }}
            </div>
          </div>
          <div class="auth4">
            <div class="authName">
              <span>Koleksi wajah</span>
            </div>
            <div
              :class="authInfo.podrrtraitStatus ? 'authSuccess' : 'unAuth'"
              @click="goFaceRecognition"
            >
              {{
                authInfo.podrrtraitStatus
                  ? "Sudah Verifikasi"
                  : "Belum Verifikasi"
              }}
            </div>
          </div>
        </div>
        <div class="checkbox">
          <img
            class="check-icon"
            @click="checked = !checked"
            :src="
              require(`@/assets/img/checked-${checked ? '' : 'no-'}icon.png`)
            "
          />
          <span class="privacy">
            Mengkonfirmasi dan Menyetujui<a @click.stop="goAgreement"
              >《Perjanjian Pinjaman》</a
            >
          </span>
        </div>
        <button type="button" class="confirm-btn" @click="onSubmit">
          Kirim
        </button>
      </div>
      <van-dialog
        v-model="visible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-tip" src="@/assets/img/location-tip.png" />
        <div class="location-confirm" @click="onLocationConfirm">
          <img src="@/assets/img/location-confirm.png" />
        </div>
      </van-dialog>
      <van-dialog
        v-model="denyVisible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-deny" src="@/assets/img/location-deny.png" />
        <div class="location-confirm" @click="onCloseDialog('denyVisible')">
          <img src="@/assets/img/location-confirm.png" />
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Toast, PullRefresh, Dialog } from 'vant'
// import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'

Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Authentication',
  mixins: [dialogVisible],
  components: {
    // VueSlickCarousel,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      isLoading: false,
      authInfo: {},
      checked: true,
      denyVisible: false,
      currentIndex: 0,
      apitype: sessionStorage.getItem('apitype') || '1'
    }
  },
  created () {
    this.getAuthInfo()
  },
  methods: {
    getAuthInfo (isRefresh) {
      request.post('audrthIdentityPage')
        .then(res => {
          console.log('信息认证', res.audrthList, JSON.stringify(res.audrthList))
          this.authInfo = res.audrthList
          isRefresh && (this.isLoading = false)
        })
    },
    beforeChange (oldSlideIndex, newSlideIndex) {
      this.currentIndex = newSlideIndex
    },
    goIdentity () {
      if (this.authInfo.iddrentityInfoStatus) {
        Toast('Anda sudah verifikasi data identitas diri')
        return
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/identity',
        query: {
          amount
        }
      })
    },
    goBasicInfo () {
      if (this.authInfo.indrformationStatus) {
        Toast('Anda sudah verifikasi data informasi dasar')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/basicInfo',
        query: {
          amount
        }
      })
    },
    goBankInfo () {
      if (this.authInfo.badrnkStatus) {
        Toast('Anda sudah verifikasi data bank')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/bankInfo',
        query: {
          type: 'auth',
          amount
        }
      })
    },
    goFaceRecognition () {
      if (this.authInfo.podrrtraitStatus) {
        Toast('Anda telah menyelesaikan verifikasi pengumpulan wajah')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      this.$router.push({
        path: '/faceRecognition'
      })
    },
    goAgreement () {
      const { amount } = this.$route.query
      this.$router.push({
        path: '/agreement',
        query: {
          amount
        }
      })
    },
    validate () {
      if (!this.checked) {
        Toast('Mengkonfirmasi dan Menyetujui "Perjanjian Pinjaman"')
        return false
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      if (!this.authInfo.podrrtraitStatus) {
        Toast('Silakan verifikasi koleksi wajah terlebih dahulu')
        return false
      }
      return true
    },
    onLocationConfirm () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log('position', position)
          this.reportLocation(position.coords).then(() => {
            this.submitOrder()
          })
        }, (err) => {
          console.log(err)
          switch (err.code) {
            case err.PERMISSION_DENIED:
              this.denyVisible = true
              this.hasDeny = true
              break
          }
        })
      }
      this.onCloseDialog()
    },
    onSubmit () {
      if (!this.validate()) return
      this.isReportLocation().then(res => {
        if (res.whetherGrab === 1) {
          if (this.hasDeny) {
            this.denyVisible = true
          } else {
            this.visible = true
          }
        } else {
          this.submitOrder()
        }
      })
    },
    submitOrder () {
      if (this.flag) return
      this.flag = true
      const { amount } = this.$route.query
      const postData = {
        apdrplicationAmount: amount,
        prdromotionChannel: 'AppStore',
        portraitAdd: 1
      }
      // 获取设备信息
      getMetadata()
        .then(res => {
          // 提交设备信息
          return request.post('deviceInformation', res)
        })
        .then(() => {
          // 提交订单
          return request.post('sudrbmitloanOrder', postData)
        })
        .then(() => {
          // 跳转到首页
          this.$router.back()
        })
        .catch(error => {
          // 错误处理
          console.error(error)
        })
        .finally(() => {
          // 结束操作
          this.flag = false
        })
    },
    isReportLocation () {
      return request.post('posisyonnanEstime')
    },
    onRefresh () {
      this.getAuthInfo(true)
    },
    reportLocation (data = {}) {
      console.log(typeof data, data, 'location')
      const { longitude = 0, latitude = 0, position = '', address = '', country = '', provinces = '', city = '', county = '', street = '' } = data
      const postData = {
        usdrerLongitude: longitude,
        usdrerLatitude: latitude,
        usdrerPositioning: position,
        usdrerAddress: address,
        usdrerCountries: country,
        usdrerProvinces: provinces,
        usdrerCity: city,
        usdrerCounty: county,
        usdrerStreet: street,
        crdrawl_source: 1
      }
      return request.post('grdrabLocation', postData)
    }
  }
}
</script>

<style scoped lang="less">
.authentication {
  display: flex;
  flex-direction: column;
  .authentication_bg {
    img {
      width: 100%;
      height: 506px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.content {
  margin: 20px;
  margin-top: 450px;
  .authList {
    margin: 0 40px;
    margin-top: 30px;
    position: relative;
    height: 120px;

    .auth1,
    .auth2,
    .auth3,
    .auth4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 30px;
      padding-right: 10px;
      height: 140px;
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 20px;
      .authName {
        display: flex;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        color: #553abb;
      }
      .unAuth,
      .authSuccess {
        width: 144px;
        height: 75px;
        border-radius: 40px;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #b549fe;
      }
      .authSuccess {
        font-weight: 500;
        background: #7059c6;
      }
    }
    .auth1 {
      top: 0;
      background: #ddd8f1;
      z-index: 99;
    }
    .auth2 {
      top: 120px;
      background: #a799db;
      z-index: 88;
    }
    .auth3 {
      top: 250px;
      background: #9a89de;
    }
    .auth4 {
      top: 375px;
      background: #8b79d1;
    }
  }
}

.authentication-item {
  position: relative;
  width: 554px;
  height: 765px;
  margin-right: 37px;
  transform: scale(0.85);

  &.slideActive {
    transform: scale(1);
  }

  &.active {
    .status {
      color: #f71f42;
    }
  }

  p {
    width: 305px;
    line-height: 33px;
    margin-left: 219px;
    font-size: 28px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }

  .label {
    padding-top: 97px;
  }

  .status {
    margin-top: 10px;
  }

  .navigation-icon {
    position: absolute;
    right: -16px;
    bottom: -29px;
    width: 99px;
    height: 99px;
    z-index: 2;
  }
}
.authentication-item1 {
}
.checkbox {
  margin-top: 440px;
  margin-bottom: 60px;
  display: flex;
  // align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  color: #fff;
  img {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    width: 500px;
  }
}

.check-icon {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.privacy {
  a {
    color: #fff;
  }
}

.confirm-btn {
  .submit-btn;
  width: 620px;
  margin-bottom: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

.location-tip {
  width: 100%;
  margin: 30px auto 0;
}

.location-confirm {
  width: 578px;
  height: 93px;
  margin: 40px auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.location-deny {
  width: 100%;
  margin: 30px auto 0;
}
/deep/ .van-dialog {
  background: #fff;
}
</style>
